import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Dynamic imports
const MenuButton = dynamic(() => import('../MenuButton'));
const MainMenu = dynamic(() => import('../../Navigation/MainMenu/Menu'));

class Navigation extends Component {
  state = {
    windowWidth: undefined
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });
  render() {
    const s = require('./navigation.module.scss');
    const { toggleMenu, opened, isMenuOpen, path, navigation } = this.props;
    return (
      <div className={s.container}>
        {navigation && <div> <MenuButton onClick={toggleMenu} open={opened} />
          <div className={`${s.NavigationContainer} ${opened && s.opened}`}>
            <ul className={s.NavigationList}>
              {this.state.windowWidth > 1024 ? <MainMenu path={path} isOpen={isMenuOpen} onClick={toggleMenu} open={opened} navigation={navigation} /> : opened && <MainMenu path={path} isOpen={isMenuOpen} onClick={toggleMenu} open={opened} navigation={navigation} /> }
            </ul>
          </div>
        </div>}
      </div>
    );
  }
}

Navigation.propTypes ={
  toggleMenu: PropTypes.func,
  opened: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  path: PropTypes.string,
  navigation: PropTypes.object
}

export default Navigation;
